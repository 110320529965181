import * as React from "react";
import { graphql, navigate } from "gatsby";
import { useLanguage } from "../contexts/languageContext";
import { useMemo } from "react";
import {
  PageHeader,
  PageWrapper,
  HeroSection,
  HeroLeft,
  QuoteHeader,
  SectionHeader,
  SectionOne,
  FourSectionTwo,
  FourSectionTwoContent,
  FourSectionThree,
  ExploreWrapper,
  ProductWrapper,
  JeanPanelWrapper,
  JeanContent,
  JeanDescription,
  JeanHeader,
  ShopTheLookHeader,
  ShopTheLookPanelWrapper,
  ShopGrid,
  PageFooter,
  Centered,
  FourSectionOneContent,
  TwoSectionOneContent,
  TwoContentGrid,
  ThreeSectionTwo,
  ThreeSectionOneContent,
  ImageLink,
  OverlayUnavailability,
  LastQuoteHeader,
  NavigationButton,
  NavigationWrapper,
  AdditionalQuote2,
  FourSectionKolQuote,
  LeftNavigation,
  RightNavigation,
  AbsoluteWrapper,
} from "../styles/individuals";
import StickySideBar from "../components/StickySideBar";
import { BorderedButton } from "../styles/common";
import { useCallback } from "react";
import { useRef } from "react";
import routes, { getIndividualRoute } from "../routes";
import GatsbyImageComponent from "../components/GatsbyImage";
import capitalize from "lodash/capitalize";
import { splitLines } from "../utils/processText";
import findIndex from "lodash/findIndex";
import ForwardArrow from "../components/ForwardArrow";
import BackwardArrow from "../components/BackwardArrow";
import { getLooks } from "../utils/processData";
import CommonIconButton from "../components/CommonIconButton";

const QuoteByRegion = ({ attitude, copies, attitudeImages }) => {
  const { theme } = useLanguage();
  return (
    <QuoteHeader>
      {!!attitudeImages ? (
        <GatsbyImageComponent
          gatsbyImageData={attitudeImages[attitude].gatsbyImageData}
          style={theme.imageFilter}
        />
      ) : (
        `${copies[`attitude_${attitude}`]}`
      )}
    </QuoteHeader>
  );
};
const ContentPattern = ({ generalCopies, copies, look }) => {
  const { isMobile, region } = useLanguage();
  const kolQuote = look.kol_quote_id
    ? splitLines(copies[`quote_${look.kol_quote_id}`])
    : "";
  const showShowJpQuote = false;
  // const showShowJpQuote = useMemo(() => {
  //   if (region !== "jp") return false;
  //   return !!look.jp_addon_quote_1;
  // }, [look, region]);

  switch (look.looks.length) {
    case 2:
      return (
        <div>
          <TwoSectionOneContent>
            <SectionHeader textAlign="center">
              {showShowJpQuote ? look.jp_addon_quote_1 : kolQuote}
            </SectionHeader>

            <TwoContentGrid>
              <div>
                <GatsbyImageComponent
                  gatsbyImageData={look.looks[1].image.gatsbyImageData}
                />
              </div>

              <Centered>
                <LastQuoteHeader>
                  {copies.last_quote}
                  {showShowJpQuote && (
                    <AdditionalQuote2>{look.jp_addon_quote_2}</AdditionalQuote2>
                  )}
                </LastQuoteHeader>
              </Centered>
            </TwoContentGrid>
          </TwoSectionOneContent>
        </div>
      );
    case 3:
      return (
        <div>
          <SectionOne>
            <div>
              <GatsbyImageComponent
                gatsbyImageData={look.looks[1].image.gatsbyImageData}
              />
            </div>
            <ThreeSectionOneContent>
              <SectionHeader>{kolQuote}</SectionHeader>
            </ThreeSectionOneContent>
          </SectionOne>
          <ThreeSectionTwo>
            <LastQuoteHeader>{copies.last_quote}</LastQuoteHeader>
            <div>
              <GatsbyImageComponent
                gatsbyImageData={look.looks[2].image.gatsbyImageData}
              />
            </div>
          </ThreeSectionTwo>
        </div>
      );
    case 4:
      return (
        <div>
          <SectionOne>
            <div>
              <GatsbyImageComponent
                gatsbyImageData={look.looks[1].image.gatsbyImageData}
              />
            </div>
            <FourSectionOneContent>
              <FourSectionKolQuote>
                <SectionHeader>
                  {showShowJpQuote ? look.jp_addon_quote_1 : kolQuote}
                </SectionHeader>
              </FourSectionKolQuote>
            </FourSectionOneContent>
          </SectionOne>
          <FourSectionTwo>
            <div />
            <FourSectionTwoContent>
              <GatsbyImageComponent
                gatsbyImageData={look.looks[2].image.gatsbyImageData}
              />
            </FourSectionTwoContent>
            <div />
          </FourSectionTwo>
          <FourSectionThree>
            <div>
              <GatsbyImageComponent
                gatsbyImageData={look.looks[3].image.gatsbyImageData}
              />
            </div>
            <div>
              <SectionHeader>
                <LastQuoteHeader>{copies.last_quote}</LastQuoteHeader>
              </SectionHeader>
              {showShowJpQuote && (
                <AdditionalQuote2>{look.jp_addon_quote_2}</AdditionalQuote2>
              )}
            </div>
          </FourSectionThree>
        </div>
      );
    default: {
      return null;
    }
  }
};

const checkUnavailability = (imageUrl) => {
  let output = "";
  const matchItem = imageUrl
    .toLowerCase()
    .match(
      "women_apparel|women_underwear|women_accessories|men_underwear|men_apparel|men_accessories|unavailable_jeans"
    );
  if (matchItem) {
    output = matchItem[0];
    if (output.includes("apparel")) {
      output = "generic_apparel";
    } else if (output.includes("underwear")) {
      output = "generic_underwear";
    } else if (output.includes("accessories")) {
      output = "generic_accessories";
    } else if (output.includes("jeans")) {
      output = "generic_jeans";
    }
  }

  return output;
};
const ShopItem = ({ product, copies }) => {
  const { region } = useLanguage();
  const unavailablyText = useMemo(() => {
    const label = checkUnavailability(product[`product_image_${region}`]?.url);
    if (!label) return "";
    return capitalize(copies[label]);
  }, [product, region, copies]);

  const productLink = useMemo(() => {
    let base = product[`product_url_${region}`];

    if (!base.includes("utm_source")) {
      let existingParams = [];
      new URL(base).searchParams.forEach((value, key) => {
        existingParams.push(`${key}=${value}`);
      });
      const utmQueryParams = `&utm_source=lookbook&utm_medium=products&utm_campaign=FA22_Digital_Lookbook`;

      if (existingParams.length > 0) {
        base = base + utmQueryParams;
      } else {
        base = base + `?${utmQueryParams}`;
      }
    }
    return base;
  }, [product, region]);
  return (
    <ImageLink href={productLink} target="_blank" rel="noreferrer">
      <GatsbyImageComponent
        gatsbyImageData={product[`product_image_${region}`]?.gatsbyImageData}
      />
      {unavailablyText && (
        <OverlayUnavailability>{unavailablyText}</OverlayUnavailability>
      )}
    </ImageLink>
  );
};

const processText = (text = "") => text?.split("\n");

const ShopTheLookPanel = ({ copies, look }) => {
  const { region } = useLanguage();

  const reorderProducts = useMemo(() => {
    const jeans = look.products.find(
      (p) =>
        p.product_url_hk?.includes("denim") ||
        p.product_url_hk?.includes("90s-straight") ||
        p.product_url_hk?.includes("jeans") ||
        p.product_url_jp?.includes("denim") ||
        p.product_url_jp?.includes("90s-straight") ||
        p.product_url_jp?.includes("jeans")
    );
    if (!jeans) return look.products;
    return [...look.products].sort((a, b) => {
      if (
        b.product_url_hk?.includes("denim") ||
        b.product_url_hk?.includes("90s-straight") ||
        b.product_url_hk?.includes("jeans")
      ) {
        return 1;
      }
      return -1;
    });
  }, [look.products]);

  return (
    <ShopTheLookPanelWrapper>
      <ShopTheLookHeader>{copies.shop_the_look}</ShopTheLookHeader>
      <ShopGrid>
        {reorderProducts.map(
          (p) =>
            !!p[`product_image_${region}`]?.gatsbyImageData && (
              <ShopItem key={p.id} product={p} copies={copies} />
            )
        )}
      </ShopGrid>
    </ShopTheLookPanelWrapper>
  );
};

const JeanPanel = ({ jean, copies, generalCopies, images, dir = "left" }) => {
  const { region, isMobile, theme } = useLanguage();

  const jeanData = useMemo(() => {
    switch (jean) {
      case "90s_straight": {
        return {
          label: copies.denim_1_label,
          desc: processText(copies.denim_1),
          image: images.denim_1,
        };
      }
      case "italian_denim": {
        if (!copies.denim_2) return null;
        return {
          label: copies.denim_2_label,
          desc: processText(copies.denim_2),
          image: images.denim_2,
        };
      }

      case "ultimate_stretch": {
        if (!copies.denim_3) return null;
        return {
          label: copies.denim_3_label,
          desc: processText(copies.denim_3),
          image: images.denim_3,
        };
      }

      case "general": {
        if (!copies.denim_general) return null;
        return {
          label: copies.denim_general,
          desc: "",
          image: images.denim_showcase,
        };
      }

      default: {
        return null;
      }
    }
  }, [copies, jean, images]);

  if (!jeanData) return null;

  const direciton = isMobile ? "left" : dir;

  return (
    <JeanPanelWrapper color={theme.textColor}>
      {direciton === "left" && (
        <GatsbyImageComponent
          gatsbyImageData={jeanData.image.gatsbyImageData}
        />
      )}
      <JeanContent dir={direciton} borderColor={theme.separatorColor}>
        <JeanHeader>{jeanData.label}</JeanHeader>
        {jeanData.desc && (
          <JeanDescription>
            {jeanData.desc.map((d, idx) => (
              <div key={idx}>{d}</div>
            ))}
          </JeanDescription>
        )}
        <BorderedButton onClick={() => navigate(`/${region}/${routes.denim}`)}>
          {generalCopies.about_the_jeans}
        </BorderedButton>
      </JeanContent>
      {direciton !== "left" && (
        <GatsbyImageComponent
          gatsbyImageData={jeanData.image.gatsbyImageData}
        />
      )}
    </JeanPanelWrapper>
  );
};

const IndividualTemplate = ({ data, pageContext }) => {
  const wrapperRef = useRef();
  const {
    lang,
    scrollToTop,
    isMobile,
    theme,
    histories,
    region,
    setFilteredLooks,
    filteredLooks,
  } = useLanguage();
  const showArrows = true;
  // const showArrows = useMemo(
  //   () =>
  //     histories.prev?.pathname?.includes("filter") ||
  //     histories.prev?.pathname?.includes("individual"),
  //   [histories]
  // );

  const {
    looks,
    copies,
    generalCopies,
    look,
    jeanType,
    images,
    attitudeImages,
  } = useMemo(() => {
    const allTags = data?.prismicLook?.data.tags.map((t) => t.tag);
    return {
      looks: getLooks(data?.prismicDataLooksByRegion.data),
      copies: data?.allPrismicCopiesIndividual?.nodes?.find(
        (h) => h.lang === lang
      )?.data,
      generalCopies: data?.allPrismicCopiesGeneral?.nodes?.find(
        (h) => h.lang === lang
      )?.data,
      look: {
        ...data?.prismicLook?.data,
        products: data?.prismicLook?.data.products
          ?.filter((p) => !!p.product.document)
          .map((p) => ({
            ...p.product.document.data,
            id: p.product.document.id,
          })),
      },
      tags: allTags,
      jeanType: data?.prismicLook?.data.denim_session,
      images: data?.prismicDataImages.data,
      attitudeImages: data?.allPrismicDataAttitudeImages?.nodes?.find(
        (h) => h.lang === lang
      )?.data,
    };
  }, [data, lang]);

  React.useEffect(() => {
    if (!filteredLooks.length) {
      setFilteredLooks(looks);
    }
  }, [setFilteredLooks, looks, filteredLooks]);

  const shareLook = useCallback(() => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=`;
    const shareLook = window.location.href;
    const width = 500;
    const height = 300;

    var left = 0,
      top = 0;
    window.open(
      url + shareLook,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" +
        width +
        ",height=" +
        height +
        ",top=" +
        top +
        ",left=" +
        left
    );
  }, []);

  const navigateToIndividual = useCallback(
    (dir) => {
      if (!dir) return;
      const increment = dir === "prev" ? -1 : 1;
      const currentIndex = findIndex(
        filteredLooks,
        (p) => p.lookid === look.lookid
      );

      let nextIndex = currentIndex + increment;
      if (nextIndex < 0) {
        nextIndex = filteredLooks.length - 1;
      } else if (nextIndex > filteredLooks.length - 1) {
        nextIndex = 0;
      }
      navigate(
        `/${region}/${getIndividualRoute(filteredLooks[nextIndex].lookid)}`
      );
    },
    [region, filteredLooks, look]
  );

  if (!copies) return null;
  return (
    <PageWrapper ref={wrapperRef} color={theme.textColor}>
      {showArrows && (
        <NavigationWrapper>
          <AbsoluteWrapper left>
            <LeftNavigation>
              <NavigationButton onClick={() => navigateToIndividual("prev")}>
              <img
                  src={`/icon/icon_arrow_${theme.darkMode ? "dark" : "light"}.png`}
                  alt={"arrow"}
                  style={{transform:'rotate(180deg)'}}
                />              </NavigationButton>
            </LeftNavigation>
          </AbsoluteWrapper>
          <AbsoluteWrapper right>
            <RightNavigation>
              <NavigationButton onClick={() => navigateToIndividual("next")}>
                <img
                  src={`/icon/icon_arrow_${theme.darkMode ? "dark" : "light"}.png`}
                  alt={"arrow"}
                />
                {/* <ForwardArrow height="100%" color={theme.textColor} /> */}
              </NavigationButton>
            </RightNavigation>
          </AbsoluteWrapper>
        </NavigationWrapper>
      )}
      <StickySideBar copies={generalCopies} />
      <HeroSection>
        <HeroLeft>
          {!isMobile && <PageHeader>{splitLines(look.kol)}</PageHeader>}
          <QuoteByRegion
            attitude={look.attitude}
            copies={copies}
            attitudeImages={attitudeImages}
          />
        </HeroLeft>
        <GatsbyImageComponent
          gatsbyImageData={look.looks[0].image.gatsbyImageData}
          loading="eager"
        />
        {isMobile && <PageHeader>{splitLines(look.kol)}</PageHeader>}
      </HeroSection>

      <ContentPattern
        look={look}
        copies={copies}
        generalCopies={generalCopies}
      />

      <ExploreWrapper>
        <BorderedButton onClick={shareLook}>
          {copies.share_the_look}
        </BorderedButton>
      </ExploreWrapper>

      <JeanPanel
        jean={jeanType}
        copies={copies}
        generalCopies={generalCopies}
        images={images}
        dir={look.looks.length === 3 ? "right" : "left"}
      />

      <ProductWrapper>
        <ShopTheLookPanel copies={copies} look={look} />
      </ProductWrapper>

      <PageFooter>
        <BorderedButton onClick={scrollToTop}>
          {generalCopies.back_to_top}
        </BorderedButton>
      </PageFooter>
    </PageWrapper>
  );
};

export const query = graphql`
  query pageQuery(
    $pageIds: [String]
    $lookId: String
    $regionLooksId: String
    $generalCopiesIds: [String]
    $generalImagesId: String
    $attitudeImagesIds: [String]
    $ecommerceLinkIds: [String]
  ) {
    allPrismicDataRegionEcommerce(filter: { id: { in: $ecommerceLinkIds } }) {
      nodes {
        id
        lang
        data {
          region
          links {
            label
            link
          }
        }
      }
    }
    allPrismicCopiesGeneral(filter: { id: { in: $generalCopiesIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          back_to_top
          inspire_me
          about_the_jeans
          filter
          dark_mode
          light_mode
          meta_share_facebook_title
          meta: meta_individual
        }
      }
    }
    allPrismicCopiesIndividual(filter: { id: { in: $pageIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          about_jeans_button
          shop_the_look
          share_the_look
          attitude_cheerful
          attitude_outgoing
          attitude_chill
          attitude_carefree
          attitude_bold
          attitude_confident
          last_quote
          quote_1
          quote_2
          quote_3
          quote_4
          quote_5
          quote_6
          denim_1
          denim_2
          denim_3
          denim_1_label
          denim_2_label
          denim_3_label
          denim_general
          generic_apparel
          generic_underwear
          generic_accessories
          generic_jeans
        }
      }
    }
    prismicDataLooksByRegion(id: { eq: $regionLooksId }) {
      data {
        looks {
          look {
            document {
              __typename
              ... on PrismicLook {
                id
                data {
                  lookid
                }
              }
            }
          }
        }
      }
    }
    allPrismicDataAttitudeImages(filter: { id: { in: $attitudeImagesIds } }) {
      nodes {
        id
        lang
        data {
          bold {
            gatsbyImageData(width: 500)
          }
          cheerful {
            gatsbyImageData(width: 500)
          }
          chill {
            gatsbyImageData(width: 500)
          }
          outgoing {
            gatsbyImageData(width: 500)
          }
          confident {
            gatsbyImageData(width: 500)
          }
          carefree {
            gatsbyImageData(width: 500)
          }
        }
      }
    }

    prismicDataImages(id: { eq: $generalImagesId }) {
      id
      lang
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        denim_showcase {
          gatsbyImageData
        }
        denim_1 {
          gatsbyImageData
        }
        denim_2 {
          gatsbyImageData
        }
        denim_3 {
          gatsbyImageData
        }
      }
    }
    prismicLook(id: { eq: $lookId }) {
      id
      lang
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        lookid
        kol
        attitude
        denim_session
        kol_quote_id
        jp_addon_quote_1
        jp_addon_quote_2
        looks {
          image {
            gatsbyImageData
            url
            dimensions {
              height
              width
            }
          }
        }
        tags {
          tag
        }
        products {
          product {
            document {
              ... on PrismicProduct {
                id
                data {
                  product_url_jp
                  product_url_kr
                  product_url_sg
                  product_url_hk
                  product_url_tw
                  product_url_my
                  product_image_jp {
                    gatsbyImageData
                    url
                    alt
                  }
                  product_image_kr {
                    gatsbyImageData
                    url
                    alt
                  }
                  product_image_sg {
                    gatsbyImageData
                    url
                    alt
                  }
                  product_image_hk {
                    gatsbyImageData
                    url
                    alt
                  }
                  product_image_tw {
                    gatsbyImageData
                    url
                    alt
                  }
                  product_image_my {
                    gatsbyImageData
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default IndividualTemplate;
export { default as Head } from "../components/Head";
